<template>
  <b-card no-body>
    <ValidationObserver v-slot="{ handleSubmit }" ref="VFormFinish">
      <b-form @submit.prevent="handleSubmit(doFinish)">
        <b-card-header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Detail Transport</h5>
            <b-button v-b-modal.modalDetail variant="outline-info">
              <i class="fas fa-eye mr-2"></i>View Booking Information
            </b-button>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row class="gutter-3">
            <b-col lg="10">
              <b-row>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Vehicle Booked</h5>
                    <span>{{row.vehicle}}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Booked by</h5>
                    <span>{{row.tbt_person}}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Booked at</h5>
                    <span v-if="row.tbt_date">{{row.tbt_date | moment('LLL')}}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Time of Use</h5>
                    <span>{{ $parent.timeOfUse(row.tbt_start_time, row.tbt_end_time) }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col md=3>
              <b-form-group>
                <label>Start Time</label>
                <date-picker :clearable="false" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm"  prefix-class="crm" v-model="row.tbt_start_time" :disabled-date="beforeStart" :disabled-time="beforeTime"/>
                <VValidate name="Start Time" v-model="row.tbt_start_time" />
              </b-form-group>
            </b-col>
            <b-col md=3>
              <b-form-group>
                <label>Finish Time</label>
                <date-picker :clearable="false" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm"  prefix-class="crm" v-model="row.tbt_end_time" :disabled-date="beforeStart" :disabled-time="beforeTime"/>
                <VValidate name="Finish Time" v-model="row.tbt_end_time" />
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col md="6" lg="4">
              <b-card class="border" header-bg-variant="info">
                <template #header>
                  <h5 class="card-title text-white">Vehicle Condition</h5>
                </template>
                <b-form-group>
                  <template #label>Current Fuel</template>
                  <p>{{ row.tt_ready_fuel }}%</p>
                </b-form-group>
                <b-form-group label-for="checkFuelInit">
                  <template #label>Last Fuel</template>
                  <v-select id="checkFuelInit" v-model="row.tt_current_fuel" placeholder="e.g. 50%" :options="dropdownPercentage" :reduce="data => data.value" />
                  <VValidate name="Last Fuel" v-model="row.tt_current_fuel" :rules="required" />
                </b-form-group>
                <b-card class="border" body-class="p-3">
                  <h5 class="card-title">Tires</h5>
                  <b-row v-if="row.tt_vehicle_type == 'motorcycle'" class="gutter-1" align-h="center">
                    <b-col cols="6">
                      <v-select v-b-tooltip.hover="'Front'" placeholder="Front" :options="dropdownDefault" v-model="row.current_tires_front" :reduce="data => data.value" />
                      <VValidate name="Front" v-model="row.current_tires_front" :rules="required" />
                    </b-col>
                    <b-col cols="6">
                      <v-select v-b-tooltip.hover="'Rear'" placeholder="Rear" :options="dropdownDefault" v-model="row.current_tires_rear" :reduce="data => data.value" />
                      <VValidate name="Rear" v-model="row.current_tires_rear" :rules="required" />
                    </b-col>
                  </b-row>
                  <b-row v-else class="gutter-1" align-h="center">
                    <b-col cols="6">
                      <v-select v-b-tooltip.hover="'Front Left'" placeholder="Front Left" :options="dropdownDefault" v-model="row.current_tires_fl" :reduce="data => data.value" />
                      <VValidate name="Front Left" v-model="row.current_tires_fl" :rules="required" />
                    </b-col>
                    <b-col cols="6">
                      <v-select v-b-tooltip.hover="'Front Right'" placeholder="Front Right" :options="dropdownDefault" v-model="row.current_tires_fr" :reduce="data => data.value" />
                      <VValidate name="Front Right" v-model="row.current_tires_fr" :rules="required" />
                    </b-col>
                    <b-col cols="6">
                      <v-select v-b-tooltip.hover="'Rear Left'" placeholder="Rear Left" :options="dropdownDefault" v-model="row.current_tires_rl" :reduce="data => data.value" />
                      <VValidate name="Rear Left" v-model="row.current_tires_rl" :rules="required" />
                    </b-col>
                    <b-col cols="6">
                      <v-select v-b-tooltip.hover="'Rear Right'" placeholder="Rear Right" :options="dropdownDefault" v-model="row.current_tires_rr" :reduce="data => data.value" />
                      <VValidate name="Rear Right" v-model="row.current_tires_rr" :rules="required" />
                    </b-col>
                    <b-col cols="6">
                      <v-select v-b-tooltip.hover="'Spare Wheel'" placeholder="Spare Wheel" :options="dropdownDefault" v-model="row.current_spare_wheel" :reduce="data => data.value" />
                      <VValidate name="Spare Wheel" v-model="row.current_spare_wheel" :rules="required" />
                    </b-col>
                  </b-row>
                </b-card>
              </b-card>
            </b-col>
            <b-col md="6" lg="4">
              <b-card class="border" header-bg-variant="info">
                <template #header>
                  <h5 class="card-title text-white">Trip Distance</h5>
                </template>
                <b-form-group>
                  <template #label>Current Speedometer</template>
                  <p>{{ $parent.formatThousand(row.tt_ready_speedometer) }}Km</p>
                </b-form-group>
                <b-form-group>
                  <template #label>Last Speedometer</template>
                  <b-input-group append="KM">
                    <b-form-input type="number" v-model="row.tt_current_speedometer"  placeholder="e.g. 29727" />
                  </b-input-group>
                  <VValidate name="Last Speedometer" v-model="row.tt_current_speedometer" :rules="{required: !!min, regex: /^\d*\.?\d*$/}" />
                </b-form-group>
                <b-row class="gutter-2">
                  <b-col md="6">
                    <div class="info-block">
                      <h5>Est. Trip Distance</h5>
                      <span v-if="row.tt_total_distance">{{+(row.tt_total_distance).toString()}}Km</span>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="info-block">
                      <h5>Actual Trip Distance</h5>
                      <span>{{+(actualDistance).toString()}} Km</span>
                    </div>
                  </b-col>
                  <b-col md="6" v-if="row.tt_use_deviasi == 'Y'">
                    <div class="info-block">
                      <h5>Deviation</h5>
                      <b-badge v-if="row.tt_deviasi_status == 'safe'" variant="success" class="text-white">Safe</b-badge>
                      <b-badge v-else-if="row.tt_deviasi_status == 'danger'" variant="danger" class="text-white">Deviated >= 20%</b-badge>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col md="4">
              <b-form-group v-if="row.tt_use_cashless == 'Y'">
                <template #label>Current Cashless Amount</template>
                <p>{{row.cashless}} : {{ $parent.formatRupiah(row.tt_ready_cashless) }}</p>
              </b-form-group>
              <b-form-group label-for="checkCashless" v-if="row.tt_use_cashless == 'Y'">
                <template #label>Last Cashless Amount</template>
                <b-input-group prepend="Rp" class="mt-1">
                  <money id="checkCashless" v-model="row.tt_current_cashless" v-bind="$parent.maskCurrency" placeholder="e.g. 100.000" class="form-control"></money>
                </b-input-group>
                <VValidate name="Last Cashless Amount" v-model="row.tt_current_cashless" :rules="min" />
              </b-form-group>
              <b-form-group>
                <template #label>Current Small Changes</template>
                <p>{{ $parent.formatRupiah(row.tt_ready_small_change) }}</p>
              </b-form-group>
              <b-form-group label-for="checkChanges">
                <template #label>Last Small Changes</template>
                <b-input-group prepend="Rp">
                  <money id="checkChanges" v-model="row.tt_current_small_change" v-bind="$parent.maskCurrency" placeholder="e.g. 100.000" class="form-control"></money>
                </b-input-group>
                <VValidate name="Last Small Change" v-model="row.tt_current_small_change" :rules="min" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <div class="info-block">
                <h5>Working Tools</h5>
                <span>{{row.tt_working_tools}}</span>
              </div>
              <b-form-group class="mt-2" label-for="checkCashless">
                <template #label>Additional Information</template>
                <b-form-textarea v-model="row.tt_add_working_tools" placeholder="e.g. barang sudah kembali dengan kondisi baik" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <div class="info-block">
                <h5>Non-working Tools</h5>
                <span>{{row.tt_non_working_tools}}</span>
              </div>
              <b-form-group class="mt-2" label-for="checkCashless">
                <template #label>Additional Information</template>
                <b-form-textarea v-model="row.tt_add_non_working_tools" placeholder="e.g. barang sudah kembali dengan kondisi baik" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <div class="info-block">
                <h5>Notes</h5>
                <span>{{row.tt_note}}</span>
              </div>
              <b-form-group class="mt-2" label-for="checkCashless">
                <template #label>Additional Information</template>
                <b-form-textarea v-model="row.tt_add_note" placeholder="e.g. barang sudah kembali dengan kondisi baik" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer class="text-right">
          <b-button type="button" @click="doFinish" variant="primary" class="btn-rounded"><i class="fas fa-check mr-2"></i>Finish Transport</b-button>
        </b-card-footer>
      </b-form>
    </ValidationObserver>
    <b-modal id="modalDetail" size="lg" ok-only ok-title="Close" ok-variant="primary btn-rounded">
      <b-tabs nav-class="mb-3">
        <b-tab title="Schedule, Routes &amp; PIC">
          <b-row class="gutter-3">
            <b-col lg="10">
              <b-row>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Vehicle Booked</h5>
                    <span>{{row.vehicle}}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Booked by</h5>
                    <span>{{ row.tbt_person }}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Booked at</h5>
                    <span v-if="row.tbt_date">{{row.tbt_date | moment('LLL')}}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Time of Use</h5>
                    <span>{{$parent.timeOfUse(row.tt_start_time, row.tt_end_time)}}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <div class="info-block">
                <h5>Routes</h5>
                <b-table-lite
                  :fields="routeFields"
                  :items="row.tt_destination"
                  striped
                  foot-row-variant="info"
                >
                  <template #cell(num)="data">{{ data.index += 1 }}</template>
                  <template #cell(distance)="data">
                    {{ $parent.formatThousand(data.value) }}Km
                  </template>
                  <template #custom-foot>
                    <b-tr>
                      <b-td colspan="3">
                        <div class="text-right">
                          Estimated Total Trip Distance:
                        </div>
                      </b-td>
                      <b-th colspan="2">{{+(row.tt_total_distance).toString()}}Km</b-th>
                    </b-tr>
                  </template>
                </b-table-lite>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Vehicle Condition &amp; Tools">
          <b-card class="border" header-bg-variant="info">
            <template #header>
              <h5 class="card-title text-white">Vehicle Condition Checking</h5>
            </template>
            <b-row class="gutter-3">
              <b-col md="3" lg="2">
                <div class="info-block">
                  <h5>Speedometer - Ready</h5>
                  <span>{{$parent.formatThousand(row.tt_ready_speedometer)}}Km</span>
                </div>
              </b-col>
              <b-col md="3" lg="2">
                <div class="info-block">
                  <h5>Fuel</h5>
                  <span class="d-block">Initial: {{ row.tt_init_fuel }}%</span>
                  <span class="d-block">Ready: {{ row.tt_ready_fuel }}%</span>
                </div>
              </b-col>
              <b-col md="6" lg="8">
                <div class="info-block" v-if="row.tt_ready_engine_check">
                  <h5>Engine Check</h5>
                  <badge-check :value="row.tt_ready_engine_check.ready_braking_system" trueValue="ok" label="Braking System" />
                  <badge-check :value="row.tt_ready_engine_check.ready_engine_oil" trueValue="ok" label="Engine Oil" />
                  <badge-check :value="row.tt_ready_engine_check.ready_accu_battery" trueValue="ok" label="Accu Battery" />
                  <template v-if="row.tt_vehicle_type == 'car'">
                    <badge-check :value="row.tt_ready_engine_check.ready_ac" trueValue="ok" label="Air Conditioner / AC" />
                    <badge-check :value="row.tt_ready_engine_check.ready_wiper_liquid" trueValue="ok" label="Wiper Liquid" />
                  </template>
                  <badge-check :value="row.tt_ready_engine_check.ready_repair_tools" trueValue="ok" label="Repair Tools" />
                </div>
              </b-col>
              <b-col md="10" lg="8">
                <b-row>
                  <b-col md="6">
                    <div class="info-block">
                      <h5>Tires - Initial</h5>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_init_tires && row.tt_vehicle_type == 'motorcycle'">
                         <b-col cols="6">
                          <badge-check :value="row.tt_init_tires.init_tires_front" trueValue="ok" label="Front" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_rear" trueValue="ok" label="Rear" />
                          </b-col>
                      </b-row>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_init_tires && row.tt_vehicle_type == 'car'">
                         <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_fl" trueValue="ok" label="Front Left" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_fr" trueValue="ok" label="Front Right" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_rl" trueValue="ok" label="Rear Left" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_rr" trueValue="ok" label="Rear Right" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_spare_wheel" trueValue="ok" label="Spare Wheel" />
                          </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="info-block">
                      <h5>Tires - Ready</h5>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_ready_tires && row.tt_vehicle_type == 'motorcycle'">
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_front" trueValue="ok" label="Front" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_rear" trueValue="ok" label="Rear" />
                        </b-col>
                      </b-row>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_ready_tires && row.tt_vehicle_type == 'car'">
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_fl" trueValue="ok" label="Front Left" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_fr" trueValue="ok" label="Front Right" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_rl" trueValue="ok" label="Rear Left" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_rr" trueValue="ok" label="Rear Right" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_spare_wheel" trueValue="ok" label="Spare Wheel" />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" lg="4">
                <div class="info-block">
                  <h5>Cleanliness</h5>
                  <template v-if="row.tt_ready_cleanliness && row.tt_vehicle_type == 'car'">
                    <badge-check :value="row.tt_ready_cleanliness.ready_clean_interior" trueValue="ok" label="Interior" />
                    <badge-check :value="row.tt_ready_cleanliness.ready_clean_exterior" trueValue="ok" label="Exterior" />
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-row class="mb-4">
            <b-col md="3">
              <div class="info-block">
                <h5>STNK</h5>
                  <template v-if="row.tt_use_stnk == 'Y'">
                    <i class="fas fa-check text-success"></i> 
                    <span class="text-success"> STNK Tersedia</span>
                  </template>
                  <template v-else>
                    <i class="fas fa-exclamation-triangle text-danger"></i> 
                    <span class="text-danger"> STNK Tidak Tersedia</span>
                  </template>
              </div>
            </b-col>
            <b-col md="3">
              <div class="info-block">
                <h5>Cashless Card</h5>
                <span class="d-block">{{row.cashless}}</span>
                <small v-if="row.tt_use_cashless == 'Y'">Amount: <span class="font-weight-semibold">{{$parent.formatRupiah(row.tt_ready_cashless)}}</span></small>
              </div>
            </b-col>
            <b-col md="3">
              <div class="info-block">
                <h5>Small Changes</h5>
                <span>{{$parent.formatRupiah(row.tt_ready_small_change)}}</span>
              </div>
            </b-col>
            <b-col md="3">
              <div class="info-block">
                <h5>Weight</h5>
                <span>{{row.tt_weight_payload}}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <div class="info-block">
                <h5>Working Tools</h5>
                <span>{{row.tt_working_tools}}</span>
              </div>
            </b-col>
            <b-col md="4">
              <div class="info-block">
                <h5>Non-working Tools</h5>
                <span>{{row.tt_non_working_tools}}</span>
              </div>
            </b-col>
            <b-col md="4">
              <div class="info-block">
                <h5>Notes</h5>
                <span>{{row.tt_note || '-'}}</span>
              </div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-modal>
  </b-card>
</template>
<script>
  import _ from 'lodash'
  import BadgeCheck from '@/components/BadgeCheck.vue'
  export default {
    components: {
      BadgeCheck
    },
    props: {
      row: Object,
      dropdown: Object,
      mrCashless: Array
    },
    data(){
      return {
        deviationPercent: 20,
         routeFields: [
          { key: 'num', label: '#' },
          { key: 'pic', label: 'Destination PIC' },
          'address',
          { key: 'distance', label: 'Est. Distance' },
          'purpose'
        ],
        routeItems: [],
        fuelPercentage: [],
        form: {}
      }
    },
    methods: {
      beforeStart(date){
        // return date ? false : false
        return new Date(date).setHours(23, 59, 59) <= new Date(this.row.tbt_start_time).setHours(0, 0, 0)
      },
      beforeTime(date){
        // return date ? false : false
        return date <= new Date(this.row.tbt_start_time)
      },
      updateDeviation(val){
        const percentageTotal = (this.deviationPercent / 100) * +this.row.tt_total_distance
        const isDanger =( +this.row.tt_total_distance + percentageTotal) <= +val
        console.log(+this.row.tt_total_distance + percentageTotal, ' >= ', +val)
        this.$set(this.row, 'tt_deviasi_status', isDanger ? 'danger' : 'safe')
      },
      doFinish(){

        this.$refs['VFormFinish'].validate().then(success => {
          if(!success) return

          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => { this.$swal.showLoading() }
          })

          return this.$parent.doSubmit('/do/' + this.$parent.modulePage, Object.assign({ type: 'finish' }, {...this.row}), (type, resp) => {
            if(type == 'success'){
              this.$swal({
                title: resp.message,
                icon: 'success',
                confirmButtonText: 'Oke'
              }).then(() => {
                this.$router.push({name: this.$route.name})
              })
            }else{
              if(resp.response.status==422) {
                var msg = ""
                global.$.each(Object.keys(resp.response.data),(i,v)=>{
                  msg = msg+" <br/>"+_.values(resp.response.data[v])
                  return global.Swal.fire("Terjadi Kesalahan Validasi!", msg)
                })
              }
              else if(resp.response.status==400){
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
              else {
                return global.Swal.fire({
                  title: 'Terjadi suatu kesalahan, silakan coba lagi nanti.',
                  text: 'Mohon periksa kembali data anda',
                  icon: 'error',
                })
              }
            }
          }, "POST", 'VFormFinish', this.$refs)

        })
      },
      setCurrentAmount(newVal){
        const current = this.mrCashless.find(v => v.value == newVal)
        if(current){
          this.$set(this.row, 'tt_ready_cashless', current.mct_current_amount)
        }
      },
      doReadyToStart(){
        this.$swal({
          title: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => { this.$swal.showLoading() }
        })

        return this.$parent.doSubmit('/do/' + this.$parent.modulePage, Object.assign({type: 'add'}, {...this.row}), (type, resp) => {
          console.log(resp)
          if(type == 'success'){
            this.$swal({
              title: resp.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(result => {
              if(result.value){
                this.$router.push({name: 'BoTransportOnGoing'})
              }
            })
          }else{
            if(resp.response.status==422) {
              var msg = ""
              global.$.each(Object.keys(resp.response.data),(i,v)=>{
                msg = msg+" <br/>"+_.values(resp.response.data[v])
                return global.Swal.fire("Terjadi Kesalahan Validasi!", msg)
              })
            }
            else if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
            else {
              return global.Swal.fire({
                title: 'Terjadi suatu kesalahan, silakan coba lagi nanti.',
                text: 'Mohon periksa kembali data anda',
                icon: 'error',
              })
            }
          }
        }, 'POST', 'VFormTwo', this.$refs)
      },
      getOptionLabel(master, id){
        if(Array.isArray(master)){
          const opt = master.find(v => v.value == id)
          if(opt){
            return opt.label
          }else{
            return id
          }
        }else{
          return id
        }
      },
      updateDestination(value, index){
        this.$set(this.row.tbt_destination[index], 'distance', value)
      },
      validateStepOne(){
        return this.$refs.VFormOne.validate().then(success => {
          if(!success) return false
          return true
        })
      },
      validateStepTwo(){
        return this.$refs.VFormTwo.validate().then(success => {
          if(!success) return false
          return true
        })
      }
    },
    computed: {
      actualDistance(){
        let actual = this.row.tt_current_speedometer - this.row.tt_ready_speedometer
        actual = +(actual.toFixed(2).toString())
        this.$set(this.row, 'tt_actual_distance', actual)
        this.updateDeviation(this.row.tt_actual_distance)
        return !this.row.tt_current_speedometer ? 0 : actual
      },
      totalEstimateDistance() {
        if(this.row.tbt_destination){
          let totalDistance = this.row.tbt_destination.reduce((a, b) => (+a || 0) + (+b.distance || 0), 0)
          totalDistance = +(totalDistance.toFixed(2).toString())
          this.$set(this.row, 'tt_total_distance', totalDistance)
          return totalDistance
        }else{
          return 0
        }
      },  
      dropdownDefault(){
        return this.dropdown.default
      },
      dropdownPercentage(){
        return this.dropdown.percentage
      },
      dropdownWeight(){
        return this.dropdown.weight
      },
      mustOK(){
        return this.row.tt_use_deviasi == 'Y' ? 'required|is:ok' : ''
      },
      required(){
        return this.row.tt_use_deviasi == 'Y' ? 'required' : ''
      },
      min(){
        return this.row.tt_use_deviasi == 'Y' ? 'required|min_value:0' : ''
      },
    }
  }
</script>
