<template>
  <b-container fluid>
    <bo-page-title />

    <b-card no-body v-if="isList">
      <template #header>
        <b-row align-h="end">
          <b-col md>
            <b-row class="gutter-1">
              <b-col md="6" lg="4" xl="3">
                <v-select @input="doFilter()" v-model="filter.vehicle" :options="mrVehicle" :reduce="data => String(data.value)" />
              </b-col>
              <b-col md="6" lg="4" xl="3">
                <v-select @input="doFilter()" v-model="filter.status" :options="mrStatus" :reduce="data => data.value" />
              </b-col>
              <b-col md="6" lg="4" xl="3"></b-col>
            </b-row>
          </b-col>
          <b-col md="auto">
            <SearchInput :value.sync="filter.search" @search="doFilter" />
          </b-col>
        </b-row>
      </template>
      <b-card-body>
         <b-table
          :fields="tableFields"
          :items="dataList || []"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          striped
          show-empty
        >
          <template #empty>
            <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
            </div>
          </template>
          <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
          <template #cell(num)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>
          <template #cell(tt_total_distance)="data">
            {{ +(data.value).toString() }}Km
          </template>
          <template #cell(tbt_person)="data">
            <div class="font-weight-semibold text-dark">{{ data.value }}</div>
            <small v-if="data.item.tbt_date">{{ data.item.tbt_date | moment('DD MMM YYYY, HH:mm') }}</small>
          </template>
          <template #cell(tbt_vehicle)="data">
            <div>{{ data.item.vehicle }}</div>
          </template>
          <template #cell(tbt_start_time)="data">
            {{ timeOfUse(data.item.tbt_start_time, data.item.tbt_end_time) }}
          </template>
          <template #cell(tt_actual_distance)="data">
            <p>Est: {{ +(data.item.tt_total_distance).toString() }}Km</p>
            <p v-if="data.value">Act: {{ +(data.value).toString() }}Km</p>
          </template>
          <template #cell(tt_status)="data">
            <b-badge v-if="data.value == 'R'" variant="info">Ready To Start</b-badge>
            <b-badge v-else-if="data.value == 'O'" variant="warning">On Going</b-badge>
            <b-badge v-else-if="data.value == 'F'" variant="success">Finish</b-badge>
            <b-badge v-else-if="data.value == 'A'" variant="danger">Abort</b-badge>
          </template>
          <template #cell(action)="data">
            <router-link v-if="moduleRole('edit')" class="btn btn-icon btn-outline-info" v-b-tooltip.hover="'View Details'" :to="{name: $route.name, params: {pageSlug: 'detail', pageId: data.item.tt_book_code } }"><i class="fas fa-eye"></i></router-link>
            <template v-if="data.item.tt_status == 'R'">
              <b-button v-if="moduleRole('start')" @click="startSchedule(data.item)" class="btn-icon" variant="outline-warning" v-b-tooltip.hover="'Start Now'"><i class="fas fa-hourglass-start"></i></b-button>
              <b-button v-if="moduleRole('abort')" @click="abortSchedule(data.item)" class="btn-icon" variant="outline-danger" v-b-tooltip.hover="'Abort'"><i class="fas fa-times"></i></b-button>
            </template>
            <template v-if="data.item.tt_status == 'O'">
              <router-link v-if="moduleRole('finish')" class="btn btn-icon btn-outline-primary" v-b-tooltip.hover="'Finish'" :to="{name: $route.name, params: {pageSlug: 'finish', pageId: data.item.tt_book_code } }"><i class="fas fa-check"></i></router-link>
            </template>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage" class="mb-0 mr-2">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage" class="mb-0 ml-2">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>
    </b-card>
    <div v-else-if="$route.params.pageSlug == 'finish'">
      <Form :row.sync="row" :dropdown="dropdown" />
    </div>
    <div v-else-if="$route.params.pageSlug == 'detail'">
      <Detail :row.sync="row" />
    </div>
  </b-container>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'
  import Form from './Form.vue'
  import Detail from './Detail.vue'
  import Gen from '@/libs/Gen.js'

  export default {
		extends: GlobalVue,
    components: {
      Form, Detail
    },
    data() {
      return {
        idKey: 'tt_id',
        statusKey: 'tt_status',
        mrValidation: {},
        tableFields: [
          { key: 'num', label: '#' },
          { key: 'tt_book_code', label: 'Booking Code' },
          { key: 'tbt_person', label: 'Name' },
          { key: 'tbt_vehicle', label: 'Vehicle' },
          { key: 'tbt_start_time', label: 'Time of Use' },
          { key: 'tt_actual_distance', label: 'Total Distance' },
          { key: 'tt_status', label: 'Status' },
          { key: 'action', label: 'Action' },
        ],
        mrVehicle: [],
        mrStatus: [],
        dropdown: {},
        mrCashless: [],
        dataModal: {}
      }
    },
    mounted(){
      this.setFilter()
      this.apiGet()
    },
    methods: {
      setFilter(){
        this.filter.status = this.$route.query.status || 'all'
        this.filter.vehicle = this.$route.query.vehicle || 'all'
        this.filter.search = this.$route.query.search || ''
      },
      startSchedule(data){
        this.$swal({
          title: 'Are you sure to start schedule now?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, sure',
          cancelButtonText: 'No, Cancel',
          reverseButtons: true,
        }).then(result => {
          if(result.value){
            this.$swal({
              title: 'Loading...',
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading()
              }
            })
            Gen.apiRest('/do/' + this.modulePage, { data: { id: data.tt_id, type: 'start' } }, "POST").then(res => {
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
              })
            }).catch(err=> {
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
          }
        })
      },
      abortSchedule(data){
        this.$swal({
          title: 'Are you sure to abort schedule?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, sure',
          cancelButtonText: 'No, Cancel',
        }).then(result => {
          if(result.value){
            this.$swal({
              title: 'Loading...',
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading()
              }
            })
            Gen.apiRest('/do/' + this.modulePage, { data: { id: data.tt_id, type: 'abort' } }, "POST").then(res => {
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
              })
            }).catch(err=> {
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
          }
        })
      }
    },
    watch: {
      $route(){
        this.setFilter()
        this.apiGet()
      }
    }
  }
</script>