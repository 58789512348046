<template>
  <div class="component-checked" :class="value == trueValue ? 'success' : 'danger'">
    <template>
      <i class="fas fa-check" v-if="value == trueValue"></i>
      <i class="fas fa-times" v-else></i>
    </template>
    <span>{{label}}</span>
  </div>
</template>
<script>
  export default {
    props: {
      value: {default:""},
      trueValue: String,
      label: {default: ""}
    }
  }
</script>
