<template>
  <b-card no-body>
    <b-card-body>
      <b-tabs nav-class="mb-3">
        <b-tab title="Schedule, Routes &amp; PIC">
          <b-row class="gutter-3">
            <b-col lg="10">
              <b-row>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Vehicle Booked</h5>
                    <span>{{row.vehicle}}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Booked by</h5>
                    <span>{{ row.tbt_person }}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Booked at</h5>
                    <span v-if="row.tbt_date">{{row.tbt_date | moment('LLL')}}</span>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="info-block">
                    <h5>Time of Use</h5>
                    <span>{{$parent.timeOfUse(row.tt_start_time, row.tt_end_time)}}</span>
                  </div>
                </b-col>
                <b-col md="3 mt-2" v-if="row.tt_finish_date">
                  <div class="info-block">
                    <h5>Finished at</h5>
                    <span v-if="row.tt_finish_date">{{ row.tt_finish_date | moment('LLL') }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <div class="info-block">
                <h5>Routes</h5>
                <b-table-lite
                  :fields="routeFields"
                  :items="row.tt_destination"
                  striped
                  foot-row-variant="info"
                >
                  <template #cell(num)="data">{{ data.index += 1 }}</template>
                  <template #cell(distance)="data">
                    {{ $parent.formatThousand(data.value) }}Km
                  </template>
                  <template #custom-foot>
                    <b-tr>
                      <b-td colspan="3">
                        <div class="text-right">
                          Estimated Total Trip Distance:
                        </div>
                      </b-td>
                      <b-th colspan="2" v-if="row.tt_total_distance
                      ">{{+(row.tt_total_distance).toString()}}Km</b-th>
                    </b-tr>
                    <b-tr v-if="row.tt_actual_distance">
                      <b-td colspan="3">
                        <div class="text-right">
                          Actual Trip Distance:
                        </div>
                      </b-td>
                      <b-th colspan="2">
                        {{+(row.tt_actual_distance).toString()}}Km
                        <div class="info-block" v-if="row.tt_use_deviasi == 'Y'">
                          <span class="mr-2">Deviation</span>
                          <b-badge v-if="row.tt_deviasi_status == 'safe'" variant="success" class="text-white">Safe</b-badge>
                          <b-badge v-if="row.tt_deviasi_status == 'danger'" variant="danger" class="text-white">Deviated >= 20%</b-badge>
                        </div>
                      </b-th>
                    </b-tr>
                  </template>
                </b-table-lite>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Vehicle Condition &amp; Tools">
          <b-card class="border" header-bg-variant="info">
            <template #header>
              <h5 class="card-title text-white">Vehicle Condition Checking</h5>
            </template>
            <b-row class="gutter-3">
              <b-col md="3" lg="2">
                <div class="info-block">
                  <h5>Speedometer - Ready</h5>
                  <span>{{$parent.formatThousand(row.tt_ready_speedometer)}}Km</span>
                </div>
                <div class="info-block" v-if="row.tt_current_speedometer">
                  <h5>Speedometer - Current</h5>
                  <span>{{$parent.formatThousand(row.tt_current_speedometer)}}Km</span>
                </div>
              </b-col>
              <b-col md="3" lg="2">
                <div class="info-block">
                  <h5>Fuel</h5>
                  <span class="d-block">Initial: {{ row.tt_init_fuel }}%</span>
                  <span class="d-block">Ready: {{ row.tt_ready_fuel }}%</span>
                  <span class="d-block" v-if="row.tt_current_fuel != null || row.tt_current_fuel != undefined">Current: {{ row.tt_current_fuel}}%</span>
                </div>
              </b-col>
              <b-col md="6" lg="8">
                <div class="info-block" v-if="row.tt_ready_engine_check">
                  <h5>Engine Check</h5>
                  <badge-check :value="row.tt_ready_engine_check.ready_braking_system" trueValue="ok" label="Braking System" />
                  <badge-check :value="row.tt_ready_engine_check.ready_engine_oil" trueValue="ok" label="Engine Oil" />
                  <badge-check :value="row.tt_ready_engine_check.ready_accu_battery" trueValue="ok" label="Accu Battery" />
                  <template v-if="row.tt_vehicle_type == 'car'">
                    <badge-check :value="row.tt_ready_engine_check.ready_ac" trueValue="ok" label="Air Conditioner / AC" />
                    <badge-check :value="row.tt_ready_engine_check.ready_wiper_liquid" trueValue="ok" label="Wiper Liquid" />
                  </template>
                  <badge-check :value="row.tt_ready_engine_check.ready_repair_tools" trueValue="ok" label="Repair Tools" />
                </div>
              </b-col>
              <b-col md="10" lg="8">
                <b-row>
                  <b-col md="6">
                    <div class="info-block">
                      <h5>Tires - Initial</h5>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_init_tires && row.tt_vehicle_type == 'motorcycle'">
                         <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_front" trueValue="ok" label="Front" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_rear" trueValue="ok" label="Rear" />
                          </b-col>
                      </b-row>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_init_tires && row.tt_vehicle_type == 'car'">
                         <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_fl" trueValue="ok" label="Front Left" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_fr" trueValue="ok" label="Front Right" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_rl" trueValue="ok" label="Rear Left" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_tires_rr" trueValue="ok" label="Rear Right" />
                          </b-col>
                          <b-col cols="6">
                            <badge-check :value="row.tt_init_tires.init_spare_wheel" trueValue="ok" label="Spare Wheel" />
                          </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="info-block">
                      <h5>Tires - Ready</h5>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_ready_tires && row.tt_vehicle_type == 'motorcycle'">
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_front" trueValue="ok" label="Front" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_rear" trueValue="ok" label="Rear" />
                        </b-col>
                      </b-row>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_ready_tires && row.tt_vehicle_type == 'car'">
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_fl" trueValue="ok" label="Front Left" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_fr" trueValue="ok" label="Front Right" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_rl" trueValue="ok" label="Rear Left" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_tires_rr" trueValue="ok" label="Rear Right" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_ready_tires.ready_spare_wheel" trueValue="ok" label="Spare Wheel" />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col md="6" v-if="row.tt_current_tires">
                    <div class="info-block">
                      <h5>Tires - Current</h5>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_current_tires && row.tt_vehicle_type == 'motorcycle'">
                        <b-col cols="6">
                          <badge-check :value="row.tt_current_tires.current_tires_front" trueValue="ok" label="Front" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_current_tires.current_tires_rear" trueValue="ok" label="Rear" />
                        </b-col>
                      </b-row>
                      <b-row align-h="center" class="gutter-1" v-if="row.tt_current_tires && row.tt_vehicle_type == 'car'">
                        <b-col cols="6">
                          <badge-check :value="row.tt_current_tires.current_tires_fl" trueValue="ok" label="Front Left" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_current_tires.current_tires_fr" trueValue="ok" label="Front Right" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_current_tires.current_tires_rl" trueValue="ok" label="Rear Left" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_current_tires.current_tires_rr" trueValue="ok" label="Rear Right" />
                        </b-col>
                        <b-col cols="6">
                          <badge-check :value="row.tt_current_tires.current_spare_wheel" trueValue="ok" label="Spare Wheel" />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" lg="4">
                <div class="info-block">
                  <h5>Cleanliness</h5>
                  <template v-if="row.tt_ready_cleanliness && row.tt_vehicle_type == 'car'">
                    <badge-check :value="row.tt_ready_cleanliness.ready_clean_interior" trueValue="ok" label="Interior" />
                  </template>
                  <template v-if="row.tt_ready_cleanliness">
                    <badge-check :value="row.tt_ready_cleanliness.ready_clean_exterior" trueValue="ok" label="Exterior" />
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-row class="mb-4">
            <b-col md="3">
              <div class="info-block">
                <h5>STNK</h5>
                  <template v-if="row.tt_use_stnk == 'Y'">
                    <i class="fas fa-check text-success"></i> 
                    <span class="text-success"> STNK Tersedia</span>
                  </template>
                  <template v-else>
                    <i class="fas fa-exclamation-triangle text-danger"></i> 
                    <span class="text-danger"> STNK Tidak Tersedia</span>
                  </template>
              </div>
            </b-col>
            <b-col md="3">
              <div class="info-block">
                <h5>Cashless Card</h5>
                <span class="d-block">{{row.cashless}}</span>
                <small v-if="row.tt_use_cashless == 'Y'">Amount: <span class="font-weight-semibold">{{$parent.formatRupiah(row.tt_ready_cashless)}}</span></small> <br />
                <small v-if="row.tt_use_cashless == 'Y'">Current Amount: <span class="font-weight-semibold">{{$parent.formatRupiah(row.tt_current_cashless)}}</span></small>
              </div>
            </b-col>
            <b-col md="3">
              <div class="info-block">
                <h5>Small Changes</h5>
                <span>Amount: {{$parent.formatRupiah(row.tt_ready_small_change)}}</span> <br />
                <span>Current Amount: {{$parent.formatRupiah(row.tt_current_small_change)}}</span>
              </div>
            </b-col>
            <b-col md="3">
              <div class="info-block">
                <h5>Weight</h5>
                <span>{{row.tt_weight_payload}}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <div class="info-block">
                <h5>Working Tools</h5>
                <span>{{row.tt_working_tools}}</span>
              </div>
              <div class="info-block" v-if="row.tt_add_working_tools != null">
                <h5>Additional Information</h5>
                <span>{{row.tt_add_working_tools || '-'}}</span>
              </div>
            </b-col>
            <b-col md="4">
              <div class="info-block">
                <h5>Non-working Tools</h5>
                <span>{{row.tt_non_working_tools}}</span>
              </div>
              <div class="info-block" v-if="row.tt_add_non_working_tools != null">
                <h5>Additional Information</h5>
                <span>{{row.tt_add_non_working_tools || '-'}}</span>
              </div>
            </b-col>
            <b-col md="4">
              <div class="info-block">
                <h5>Notes</h5>
                <span>{{row.tt_note || '-'}}</span>
              </div>
              <div class="info-block" v-if="row.tt_add_note != null">
                <h5>Additional Information</h5>
                <span>{{row.tt_add_note || '-'}}</span>
              </div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>
<script>
  import BadgeCheck from '@/components/BadgeCheck.vue'
  export default {
    components: {
      BadgeCheck
    },
    props: {
      row: Object,
    },
    data(){
      return {
         routeFields: [
          { key: 'num', label: '#' },
          { key: 'pic', label: 'Destination PIC' },
          'destination',
          { key: 'distance', label: 'Est. Distance' },
          'purpose'
        ],
      }
    },
  }
</script>

